import { graphql, useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { colors, spacing } from '../styles/variables'

const query = graphql`
  {
    niki: file(relativePath: { eq: "profiles/niki.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    maja: file(relativePath: { eq: "profiles/maja.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    adelka: file(relativePath: { eq: "profiles/adelka.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    jana: file(relativePath: { eq: "profiles/jana.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    andrea: file(relativePath: { eq: "profiles/andrea.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
    jan: file(relativePath: { eq: "profiles/jan.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 700) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`

export enum ProfileName {
  Maja = 'maja',
  Niki = 'niki',
  Adelka = 'adelka',
  Jana = 'jana',
  Andrea = 'andrea',
  Jan = 'jan',
}

interface StyledProps {
  index: number
}

const Container = styled(Img)<StyledProps>`
  width: ${spacing.size13};
  min-height: 500px;
  position: relative;

  overflow: visible !important;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;

    transform: translateX(${spacing.size06}) translateY(${spacing.size06});

    ${({ index }) =>
      index % 2 === 0
        ? css`
            background-color: ${colors.white};
          `
        : css`
            background-color: ${colors.main};
          `};
  }
`

interface Props extends StyledProps {
  className?: string | undefined
  name: ProfileName
}

const ProfileImage: FC<Props> = (props) => {
  const { name } = props
  const data = useStaticQuery(query)

  // @ts-ignore
  return <Container fluid={data[name].childImageSharp.fluid} {...props} />
}

export default ProfileImage
