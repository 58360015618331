import styled from 'styled-components'
import { colors } from '../styles/variables'

interface Props {
  color?: string | undefined
  size?: number | undefined
}

const HeadingTwo = styled.h2<Props>`
  font-size: ${(props) => props.size || 48}px;
  color: ${(props) => props.color || colors.white};
`

export default HeadingTwo
